$content-width: calc(40vw - 84px);
$margin: 20px;
$spacing: 20px;
$bdrs: 6px;
$circle-size: 40px;
$icon-size: 32px;
$bdrs-icon: 100%;

$color1: #9251ac;
$color2: #f6a4ec;

$color3: #87bbfe;
$color4: #555ac0;

$color5: #24b47e;
$color6: #aff1b6;
* {
  box-sizing: border-box;
}
html {
  font-size: 14px;
}
body {
  background: #f6f9fc;
  font-family: "Open Sans", sans-serif;
  color: #525f7f;
}
h2 {
  margin: 5%;
  text-align: center;
  font-size: 2rem;
  font-weight: 100;
}
html {
    font-size: 14px;
  }
  body {
    background: #f6f9fc;
    font-family: "Open Sans", sans-serif;
    color: #525f7f;
  }
  h2{
    margin: 5%;
    text-align: center;
    font-size: 2rem;
    font-weight: 100;
  }
  .timeline {
    text-align: center;
    padding: 40px 0px 40px 0px;
    .yellow-icon{
      background: #eaca2a !important;
    }
    &__event {
      background: #fff;
      z-index: -1;
      margin-bottom: 20px;
      vertical-align: top;
      position: relative;
      display: inline-block;
      width: 40%;
      min-width: 270px;
      margin: 20px;
      border-radius: 8px;
      box-shadow: 0 30px 60px -12px rgba(50, 50, 93, 0.25),
        0 18px 36px -18px rgba(0, 0, 0, 0.3),
        0 -12px 36px -8px rgba(0, 0, 0, 0.025);
  
      &__title {
        font-size: 1.2rem;
        line-height: 1.4;
        text-transform: uppercase;
        font-family: 'Superfats' !important;        
        font-weight: 600;
        color: #9251ac;
        letter-spacing: 1.5px;
      }
      &__content {
        padding: 20px;
      }
      &__date {
        color: #f6a4ec;
        font-size: 1.5rem;
        font-weight: 600;
        white-space: nowrap;
      }
      &__icon {
        border-radius: 0px 0 0 0px;
        background: #9251ac;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-basis: 40%;
        font-size: 2rem;
        color: #9251ac;
        padding: 20px;
  
        i {
          position: absolute;
          top: 50%;
          left:-65px;
          font-size: 2.5rem;
        transform: translateY(-50%);
          
        }
      }
      &__description {
        flex-basis: 60%;
      }
  
      &:after {
        // content: "";
        width: 2px;
        height: 100%;
        background: #9251ac;
        position: absolute;
        top: 52%;
        // transform: translateY(-50%);
        left: -3.5rem;
        z-index: -1;
        
      
      }
  
      &:before {
        // content: "";
        width: 5rem;
        height: 5rem;
        position: absolute;
        background: #f6a4ec;
        border-radius: 100%;
        left: -6rem;
        right: 90%;
        top: 50%;
        transform: translateY(-50%);
        border: 2px solid #9251ac;
      }
      &--type2 {
        &:before {
          background: #87bbfe;
          border-color: #555ac0;
        }
        &:after{
          background: #555ac0;
    
        }
        .timeline__event__date {
          color: #87bbfe;

        }
  
        .timeline__event__icon {
          background: #555ac0;
          color: #555ac0;
        }
        .timeline__event__title {
          color: #555ac0;
        }
        .timeline__event__title {
        }
      }
  
      &--type3 {
        &:before {
          background: #aff1b6;
          border-color: #24b47e;
        }
        &:after{
          background: #24b47e;
    
        }
        .timeline__event__date {
          color: #aff1b6;
        }
  
        .timeline__event__icon {
          // background: #24b47e;
          background: url("./conchas.png");
          background-size: contain;
          color: #24b47e;
        }
        .timeline__event__title {
          color: #24b47e;
        }
        .timeline__event__title {
        }
      }
        &:last-child{
  
          &:after{
            content: none;
          }
      }
      
    }
  }

  .timeline__event{
    &:nth-child(even){
        animation: floatLeft 6s ease-in-out infinite;
    }    
    &:nth-child(odd){
        animation: floatRight 6s ease-in-out infinite;
    }    
  }

  .timeline__event__icon img{
    width: 3em;
    height: 3em;
  }
  @media (max-width: 786px) {
    .timeline__event {
      flex-direction: column;
    }
    .timeline__event__icon {
      border-radius: 4px 4px 0 0;
    }
    .timeline__event__date {
        margin: -30px 0px 0px 20px;
    }
    .timeline__event{
        &:nth-child(3n){
            animation: floatLeft 6s ease-in-out infinite;
        }    
        &:nth-child(odd){
            animation: floatRight 6s ease-in-out infinite;
        }    
      }
  }
  

  @keyframes floatLeft {
	0% {
		box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
		transform: translatex(0px);
	}
	50% {
		box-shadow: 0 25px 15px 0px rgba(0,0,0,0.2);
		transform: translatex(-15px);
	}
	100% {
		box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
		transform: translatex(0px);
	}
}
  @keyframes floatRight {
	0% {
		box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
		transform: translatex(0px);
	}
	50% {
		box-shadow: 0 25px 15px 0px rgba(0,0,0,0.2);
		transform: translatex(15px);
	}
	100% {
		box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
		transform: translatex(0px);
	}
}
