.the-team-container
    text-align: center
    padding: 40px 0px 80px 0px
    background: #9644ed
    h2
        font-size: 4em
        color: #e1e1e1
        font-weight: 600
        font-family: 'Superfats' !important

.the-team
    max-width: 350px
    background: #895514
    color: #ffffff
    position: relative
    display: inline-block
    text-align: center
    margin: 20px
    font-size: 2em
    box-shadow: 0px -3px 12px 0px #9647ea, -7px 6px 36px -18px #470889, 3px 1px 4px -8px #38076c
    img
        width: 42%
        margin: 20px
    h3
        font-family: 'Bounties'
.faq-container
    overflow: hidden
    position: relative
    text-align: center
    color: #ffffff
    background: #f49af6
.faq-container svg
    grid-column: 1 / span 8
    fill: #FE840E
    transition: 0.3s all
.faq-header h1
    animation: animation 1000ms linear both
    font-size: 4em
    font-family: 'Superfats'
    color: #000
.faq-question
    color: #000
    font-family: Bounties
    h2
        font-size: 3em    
.close-icon-wrapper
    font-size: 5em
    color: #bfb1ab
    margin: 0px 50px 50px 0px
    position: absolute
    right: 5px
    top: 15px
    text-align: right
.faq-answer
    padding: 30px
    font-size: 23px
    text-align: left
    margin-bottom: 20px
    overflow: hidden
    li
        margin-bottom: 15px
    .recipe-link
        display: block
    p, .recipe-link
        margin: auto
        width: 88%
    a
        font-family: Bounties
        font-weight: bolder
        text-decoration: none
        color: #0012ff
.instagram
    color: #673ab7
.bold-text
    font-weight: bold
.faq-q
    position: absolute
    left: 10px
    font-size: 85px
    color: #f11
    font-weight: bolder
    margin-top: -65px
.faq-answervisibel
    background: red
.mint-container-background
    background: red
    z-index: -1
    height: 100%
    position: absolute
    width: 100%
    background-repeat: no-repeat
    background: url("./conchas_white.png")
    background-size: 50%
    background-position: 106% 100%
.loading-animation
    height: 215px
    width: 180px
    text-align: center
.NFT-container
    display: inline-block
    box-shadow: rgb(0 0 0) 1px 1px 0px, rgb(0 0 0) 0px 0px 0px 0px
    position: relative
    color: rgb(255, 255, 255)
    vertical-align: top
    height: 215px
    width: 180px
    margin: 15px
    border-radius: 15px
    text-align: center
    cursor: pointer    
    img
        margin-top: 5%
        max-width: 335px
        width: 30%
.see-more-button
    text-align: center
    button
        border: 0px
        padding: 15px
        margin: 30px
        font-family: "Bounties"
        font-size: 20px
        cursor: pointer
        color: #000
        background: #fff
        &:hover
            background: #ded3d3
@media (max-width: 600px)
    .display-image-container
        width: 50%
    .inventory
        padding: 0px
    .NFT-container img
        margin: 10%
        width: 70%
    .nft-image-focus
        margin-top: 15%
    .close-icon-wrapper
        margin: 0px 25px 25px 0px    
        