.appContainer {
  text-align: center;
  overflow: hidden !important;
  position: relative;
}

.MuiPaper-elevation1{
  box-shadow: none !important;
  width: 70% !important;
}

.kQMbuS{
  width: 85% !important;
}
/* .gAAyGs{
  color: #f1f1f1 !important;
  background: #f49af6 !important;
} */
.MuiButton-label{
  font-family: "Bounties" !important;
  font-size: 1.5em !important;
}

.MuiTypography-colorTextSecondary{
  font-family: 'Superfats' !important;   
  color: #000 !important;  
}
.MuiTypography-colorTextPrimary{
  color: #000 !important;
  font-family: 'Bounties' !important;    
}
.makeStyles-done-3{
  color: #fe6346 !important;  
  font-family: 'Bounties' !important;    
}
.MuiGrid-grid-xs-3{
  max-width: 50% !important;
  flex-basis: 50% !important;  
}
.MuiGrid-wrap-xs-nowrap{
  flex-wrap: wrap !important;
}
.token-image-container{
  /* background: #dea552 */
}
.display-image-container{
  vertical-align: top;
}
modal-overlay
.modal{
  display: none;
}
.modalOpen{
  display: block;
  background: #ff98fb;
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 0;
  width: 100%;
  height: 100%;
}
.modal-container{
  font-family: 'Bounties';
  margin: auto;
  position: fixed;
  left: 0;
  z-index: 1000;
  right: 0;
  top: 0;
  bottom: 0;
  text-align: center;
  box-shadow: -2px 5px 10px #73006f;
}
.MuiAlert-standardError .MuiAlert-message{
    background: #f59af6;
    padding: 15px !important;
    border-radius: 24px;
    box-shadow: 3px 5px 6px 0px #000;
}
.MuiAlert-standardError .MuiAlert-action{
    display: flex;
    align-items: center;
    margin-left: auto;
    padding-left: 0px;
    bottom: 78px;
    position: absolute;
    right: -7px;
}
.MuiAlert-standardError .MuiAlert-icon{
  display: none;
}
.minted-bakery-title{
  font-size: 4em;
}
.mint-count{
  font-size: 2em;
  font-family: 'Bounties';
  position: relative;
  bottom: 25px;
}
.low-on-bread{
  font-family: 'Superfats' !important;
  font-size: 3em;  
}
.inventory{
  overflow: hidden;
}
@media (max-width: 450px){

  .token-image-container{
    padding: 1px !important;
  }  
  
}
@media (min-width: 1280px){
  
  .MuiContainer-maxWidthLg{
    max-width: 100% !important;
  }

}
.slideDown {
  left:5px;
}


@keyframes slideDown {
  0% {transform: translateY(-170px);}
  100% {transform: translateY(20px);opacity:1;}
}

@keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: center center;
            transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
            transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
            transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
            transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
}

.heartbeat {
	-webkit-animation: heartbeat 1.5s infinite both;
	        animation: heartbeat 1.5s infinite both;
}

.loading:after {
  content: ' .';
  animation: dots 1s steps(5, end) infinite;}


@keyframes dots {
  0%, 20% {
    color: rgba(0,0,0,0);
    text-shadow:
      .25em 0 0 rgba(0,0,0,0),
      .5em 0 0 rgba(0,0,0,0);}
  40% {
    color: white;
    text-shadow:
      .25em 0 0 rgba(0,0,0,0),
      .5em 0 0 rgba(0,0,0,0);}
  60% {
    text-shadow:
      .25em 0 0 white,
      .5em 0 0 rgba(0,0,0,0);}
  80%, 100% {
    text-shadow:
      .25em 0 0 white,
      .5em 0 0 white;}
  }