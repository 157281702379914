body {
  background: #f6f9fc;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
p{
  cursor: default;
}
.MuiDialogTitle-root h2 {
  font-family: 'Superfats' !important;  
}
.MuiList-padding {
  background: #fff !important;
}
.jss1 .MuiDialogTitle-root{
  background: #ac168c !important;
}
.MuiContainer-root .MuiContainer-maxWidthLg{
  margin: 200px 0px 240px 0px !important;
}
.eNsQMy{
  background: #000 !important;
  color: #fff !important;
}
.MuiListItem-gutters{
  background: #ff4cd9 !important;
}
.makeStyles-root-1 .MuiDialogContent-root .MuiListItem-root .MuiButton-root{
  font-family: 'Bounties' !important;  
  font-size: 1.4em !important;
}
.social-media{
  background: #f6f9fc;
  text-align: center;
  padding: 70px 0px 70px 0px;
}
.MuiPaper-rounded{
  border-radius: 30px !important;
}
.social-media-image-container {
  display: inline-block;
  width: 23%;
}
.social-media-image-container img{
  width: 50%;
}
.MuiPaper-root{
  background: transparent !important;
}
.token-options{
    background: white;
    width: 265px;
    display: inline-block;
    padding: 10px;
    border: 1px solid #a0a0a0;
    text-align: left;
    color: #000;
    border-radius: 15px;
}
@font-face{
  font-family: Superfats;
  src: url("./Superfats-6PRM.ttf")
}

@font-face{
  font-family: Bounties;
  src: url("./Bounties-8MjWZ.ttf")
}
.MuiButton-contained{
  background: #f49cf6 !important; 
}

.wave {
  position: relative;
  /* display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; */
  min-height: 45vh;
  background-color: #f6f9fc;
  font-family: Roboto;
  overflow: hidden;
  

}

  .wave:before,
  .wave:after {
    content: "";
    position: absolute;
    left: 50%;
    top: 65%;
    min-width: 310vw;
    min-height: 300vw;
    background-color: #f49af6;
    animation-name: rotate;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
  
  .wave:before {
    border-radius: 45%;
    animation-duration: 10s;
  }
  
  .wave :after {
    opacity: .5;
    border-radius: 47%;
    animation-duration: 10s;
  }

@keyframes rotate {
  0% {transform: translate(-50%, 0) rotateZ(0deg);}
  50% {transform: translate(-50%, -2%) rotateZ(180deg);}
  100% {transform: translate(-50%, 0%) rotateZ(360deg);}
}
@-webkit-keyframes rotate {
  0% {transform: translate(-50%, 0) rotateZ(0deg);}
  50% {transform: translate(-50%, -2%) rotateZ(180deg);}
  100% {transform: translate(-50%, 0%) rotateZ(360deg);}
}
@-moz-keyframes rotate {
  0% {transform: translate(-50%, 0) rotateZ(0deg);}
  50% {transform: translate(-50%, -2%) rotateZ(180deg);}
  100% {transform: translate(-50%, 0%) rotateZ(360deg);}
}
@-o-keyframes rotate {
  0% {transform: translate(-50%, 0) rotateZ(0deg);}
  50% {transform: translate(-50%, -2%) rotateZ(180deg);}
  100% {transform: translate(-50%, 0%) rotateZ(360deg);}
}
@keyframes rotate { 
  0% {transform: translate(-50%, 0) rotateZ(0deg);}
  50% {transform: translate(-50%, -2%) rotateZ(180deg);}
  100% {transform: translate(-50%, 0%) rotateZ(360deg);}
}